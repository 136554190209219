// Disable Frontend Module Editing
a.btn.jmodedit {
	display: none !important;
}

.content_fixed_right {
	right: 0
}

.content_fixed_left {
	left: 0
}

.content_fixed_right, .content_fixed_left {
	position: fixed;
	z-index: 999;
	top: 24px;
	.moduletable {
		position: relative;
		margin-bottom: 24px;
		&.fixedSocial {
			margin-right: 0;
			width: auto;
			box-shadow: none;
			top: 23vh;
			display: none;
			@media #{$large}{
				display: block;
			}
			ul {
				list-style: none;
				margin-left: 0;
				margin-bottom: 12px;
				position: relative;
				li {
					text-align: center;
					margin-bottom: 6px;
					&:before {
						display: none;
					}
					i {
						@include font-size(24);
					}
					a {
						display: block;
						color: $light;
						padding: 2px 6px;
						background: $link;
						box-shadow: -1px 3px 12px -5px rgb(0 0 0 / 51%);
						border-radius: 17px 0 0 17px;
						&:hover {
							background: $main;
						}
						span {
							@include font-size(11, 1.4);
							text-align: center;
							display: block;
						}
					}
				}
			}
		}
	}
}

.content_main_right {
	.moduletable {
		@include bgblock();
	}
}

.moduletable {
	&.news {
		@include clear();
		margin-bottom: 24px;
		> h2 {
			text-align: center;
		}
		.newsflashBox {
			box-shadow: 0px 3px 6px #00000029;
			border-radius: 0;
			background: $bgbox;
			overflow: hidden;
			position: relative;
			padding-bottom: 48px;
			height: 100%;
			figure {
				margin: 0;
				@media #{$large}{
					min-height: 370px;
					display: flex;
					align-items: center;
				}
				img {
					display: block;
					margin: 0;
					width: 100%;
				}
			}
			.textBlock {
				padding: 24px;
				padding-bottom: 0;
				background: $dark;
				color: $light;
				position: relative;
				z-index: 1;
				* {
					color: $light;
				}
				h4 {
					@include font-size(20, 1.4);
					font-weight: 700;
				}
				&:before {
					content: "";
					z-index: -1;
					top: -40px;
					min-height: 50px;
					border-radius: 50% 50% 0 0;
					background: var(--dark);
					display: block;
					position: absolute;
					left: 50%;
					width: 1200px;
					height: 1200px;
					transform: translate(-50%);
				}
			}
			a.readmore {
				@include button();
				position: absolute;
				bottom: 24px;
				left: 24px;
				z-index: 2;
				color: $light;
				border-color: $light;
				&:hover {
					background: $light;
					color: $dark;
				}
			}
			.introBox {
				position: relative;
				overflow: hidden;
				max-height: 186px;
				&:after {
					content: '';
					position: absolute;
					display: block;
					width: 100%;
					bottom: 0;
					height: 93px;
					/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,0.74+19,1+45,1+72 */
					background: -moz-linear-gradient(top, rgba(#333, 0) 0%, rgba(#333, 0.74) 19%, rgba(#333, 1) 45%, rgba(#333, 1) 72%, rgba(#333, 1) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top, rgba(#333, 0) 0%, rgba(#333, 0.74) 19%, rgba(#333, 1) 45%, rgba(#333, 1) 72%, rgba(#333, 1) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom, rgba(#333, 0) 0%, rgba(#333, 0.74) 19%, rgba(#333, 1) 45%, rgba(#333, 1) 72%, rgba(#333, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
				}
			}
		}
	}
	&.preisboxen {
		padding-top: 24px;
		> h3 {
			text-align: center;
		}
		.custom.preisboxen {
			max-width: 100%;
			width: 750px;
			margin: 0 auto;
			padding-bottom: 72px;
			.wf-columns {
				margin-top: 72px;
				.wf-column {
					text-align: center;
					padding: 12px;
					box-shadow: 0 7px 10px -5px #333;
					transition: all .3s cubic-bezier(.2, .5, .3, 1);
					p {
						margin-bottom: 6px;
					}
					&:nth-child(2) {
						border: 4px solid $link;
						background: $light;
						z-index: 1;
						@media #{$large}{
							transform: scale(1.2);
							&:hover {
								z-index: 2;
								transform: translateY(-5px) scale(1.2);
								box-shadow: 0px 8px 16px #00000099;
							}
						}
					}
					&:first-child, &:last-child {
						background: $link;
						color: $light;
						* {
							color: $light;
						}
						.btn {
							@include button($light, $link);
							color: $base;
						}
					}
					&:hover {
						z-index: 2;
						transform: translateY(-5px);
						box-shadow: 0px 8px 16px #00000099;
					}
				}
			}
		}
	}
	&.bg {
		> .custom.bg {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			padding-top: 48px;
			padding-bottom: 24px;
		}
	}
	&.trenner {
		padding-top: 48px;
		padding-bottom: 48px;
		.custom {
		}
	}
	.mod-finder {
		input[type='text'] {
			border-radius: 6px 0 0 6px;
			min-height: 24px;
			padding: 2px 6px 1px;
			@include font-size(14);
		}
		button.btn.btn-primary {
			border-radius: 0 6px 6px 0;
			min-height: 24px;
			padding: 2px 6px 1px;
			border: 1px solid $link;
			border-left: 0;
			@include font-size(14);
			transform: none !important;
		}
	}
	&.no_print {
		.contact_form {
			hr {
				display: none;
			}
		}
	}
	&.grey{
		@include clear();
		padding-top: 24px;
		h2{
			color: $base;
			strong{
				color: $link;
			}
		}
	}
}

// Konfigurator
#tbl_mask {
	table#tbl_maskfilter {
		display: table;
	}
	input.form-control.input-sm {
		box-sizing: border-box;
		border: 1px solid $border;
		margin-left: 24px;
	}
}