// Zeug fürs Grid
@use "sass:math";
@mixin clearfix() {
	*zoom:1;
	&:before, &:after { content: " "; display: table; }
	&:after { clear: both; }
}

@mixin ghostVerticalAlign(){
	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: 100%; width: .1px;
	}
}
// Button
@mixin button($bgcolor: $link, $hover: $main) {
	background: transparent;
	border: 2px solid $bgcolor;
	color: $bgcolor;
	padding: 9px 20px;
	display: inline-block;
	border-radius: 6px;
	font-weight: 600;
	transition: all .3s cubic-bezier(.2,.5,.3,1);
	&:hover {
		background: $bgcolor;
		color: $light;
	}
	&.btn-transparent{
		background-color: transparent;
		border: 2px solid $light;
		color: $light;
		&:hover{
			border: 2px solid $light;
			background: $light;
			color: $base;
		}
	}
	&.btn-dark{
		background-color: transparent;
		border: 2px solid $dark;
		color: $dark;
		&:hover{
			border: 2px solid $dark;
			background: $dark;
			color: $light;
		}
	}
}
@mixin inheriter(){
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
}
// Hintergrundblock
@mixin bgblock(){
	margin-bottom: 24px;
	padding: 12px;
	@media #{$large}{
		padding: 24px;
	}
	background: $light;
	display: block;
	float: left;
	width: 100%;
	box-shadow: $box-shadow;
}
// Clear all
@mixin clear() {
	float: left;
	width: 100%;
	clear: both;
	display: block;
}
// Full width Hintergrundfarbe
@mixin bgfull($bgcolor: $main, $spacing: 48px){
	background-color: $bgcolor;
	position: relative;
	padding-top: $spacing;
	padding-bottom: $spacing;
	float: left;
	display: block;
	width: 100%;
	&:before, &:after{
		content:'';
		position: absolute;
		height: 100%;
		width: 500%;
		background-color: $bgcolor;
		top: 0;
	}
	&:before{
		right: 100%;
	}
	&:after{
		left: 100%;
	}
}
@mixin bgfullflex($bgcolor: $main, $spacing: 48px){
	background-color: $bgcolor;
	position: relative;
	padding-top: $spacing;
	padding-bottom: $spacing;
	width: 100%;
	&:before, &:after{
		content:'';
		position: absolute;
		height: 100%;
		width: 500%;
		background-color: $bgcolor;
		top: 0;
	}
	&:before{
		right: 100%;
	}
	&:after{
		left: 100%;
	}

}
// Use Rem and Pixel Fallback
@mixin font-size($sizeValue: $base_font_math, $lineHeight: 1.6) {
	font-size: ($sizeValue) + px;
	font-size: math.div($sizeValue, $base_font_math ) + rem;
	line-height: $lineHeight;
}
// Icon to before
@mixin icon($inputicon, $dir: left) {
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@if $dir == "left" {
		&:before {
			content: $inputicon;
			font-family: $icon;
			font-size: inherit;
			padding-right: 6px;
		}
	}
	@if $dir == "none" {
		&:before {
			content: $inputicon;
			font-size: inherit;
			font-family: $icon;
		}
	}
	@if $dir == "right" {
		&:after {
			content: $inputicon;
			font-size: inherit;
			font-family: $icon;
			padding-left: 6px;
		}
	}
}

// Keyframes
@keyframes ripple {
	0% {
		box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
	}
	50% {
		box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
	}
	100% {
		box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
	}
}
