.blog, .blog-featured {
	@include clear();
	.category-desc {
		@include clear();
		background: $bgbox;
		padding: 12px;
		height: 100%;
		position: relative;
		box-shadow: $box-shadow;
		margin-bottom: 24px;
		@media #{$large}{
			padding: 24px;
			padding-bottom: 0;
		}
		img {
			width: 150px;
			margin-right: 24px;
			margin-bottom: 24px;
			float: left;
		}
	}
	.blog-items {
		display: flex;
		flex-wrap: wrap;
		margin-left: -12px;
		margin-right: -12px;
		@media #{$large}{
			margin-left: -24px;
			margin-right: -24px;
		}
		&.masonry-2, &.columns-2 {
			.blog-item {
				width: 100%;
				@media #{$medium}{
					width: 50%;
				}
				.itemWrap {
					figure.left {
						width: 100%;
					}
					.item-content {
						overflow: hidden;
						position: relative;
						padding-bottom: 72px;
						height: 100%;
						p.readmore {
							position: absolute;
							bottom: 0;
						}
					}
				}
				.textBlock {
					height: 186px;
					position: relative;
					&:after {
						content: "";
						position: absolute;
						display: block;
						width: 100%;
						bottom: 0;
						height: 93px;
						background: linear-gradient(180deg, rgba($light, 0) 0, rgba($light, .74) 19%, rgba($light, 1) 100%);
					}
				}
			}
		}
		&.masonry-3, &.columns-3 {
			.blog-item {
				width: 100%;
				@media #{$medium}{
					width: 50%;
				}
				@media #{$large}{
					width: 33.333333%;
				}
				.itemWrap figure.left {
					width: 100%;
				}
				.item-content {
					display: flex;
					flex-wrap: wrap;
				}
				.itemWrap {
					h2 {
						@include font-size(22);
					}
					.item-content {
						overflow: hidden;
						position: relative;
						height: 100%;
						p.readmore {
							position: absolute;
							bottom: 0;
							z-index: 10;
						}
					}
				}
				.textBlock {
					height: 300px;
					position: relative;
					align-self: flex-end;
					overflow: hidden;
					&:after {
						content: "";
						position: absolute;
						display: block;
						width: 100%;
						bottom: 0;
						height: 180px;
						background: linear-gradient(180deg, rgba(#fff, 0), rgba(#fff, 1) 44%);
					}
				}
			}
		}
		&.masonry-4, &.columns-4 {
			.blog-item {
				width: 100%;
				@media #{$medium}{
					width: 50%;
				}
				@media #{$large}{
					width: 25%;
				}
				.itemWrap figure.left {
					width: 100%;
					padding: 0;
				}
				.itemWrap {
					h2 {
						@include font-size(20);
					}
				}
				.textBlock {
					position: relative;
				}
				.item-content {
					display: flex;
					flex-wrap: wrap;
					height: 100%;
					.textBlock {
						align-self: flex-end;
					}
				}
			}
		}
		.blog-item {
			padding-left: 12px;
			padding-right: 12px;
			margin-bottom: 24px;
			@include clear();
			transition: .3s all ease 0s;
			@media #{$large}{
				padding-left: 24px;
				padding-right: 24px;
				margin-bottom: 48px;
			}
			.itemWrap {
				@include clear();
				background: $bgbox;
				padding: 12px;
				height: 100%;
				position: relative;
				box-shadow: $box-shadow;
				@media #{$large}{
					padding: 24px;
					padding-bottom: 0;
				}
				.textWrap {
					width: 100%;
					@media #{$small}{
						width: 60%;
					}
					@media #{$large}{
						width: 75%;
					}
					float: left;
				}
				figure.left {
					width: 100%;
					@media #{$small}{
						width: 40%;
					}
					@media #{$large}{
						width: 25%;
					}
					margin-top: auto;
					margin-bottom: auto;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					margin-right: 0;
					padding-right: 24px;
				}
				.item-content {
					overflow: hidden;
					h2 {
						line-height: 1.35;
					}
					dl.article-info {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.items-row {
		@include clear();
		margin-bottom: 24px;
	}
	&.com-content-categories__items {
		.itemWrap .item-content img {
			@media #{$large}{
				margin-bottom: 12px;
			}
		}
	}
}

.bfQuickMode {
	@include bgblock();
	legend {
		font-weight: bold;
		margin-bottom: 24px;
	}
}

.category-list {
	@include clear();
	@include bgblock();
	form#adminForm {
		.com-content-category__table {
			th {
				font-weight: 500;
				a {
					padding: 2px 4px;
				}
				&:hover {
					background: $link;
					color: $light;
					a {
						color: $light;
					}
				}
			}
		}
	}
}

body.img-shadow-7 {
	.blog-item {
		.itemWrap {
			p {
				display: none;
			}
			.page-header h2 {
				@include font-size(20);
				text-align: center;
			}
		}
	}
}

ul.fbContent_topics ul.level2 {
	display: none;
}

.fbContent_empty {
	display: none;
}

@media #{$xlarge}{
	.fbContent_core {
		max-width: 80%;
		padding-left: 24px;
	}
	.fbLeftNavigation_core.fb-hidden {
		max-width: 20%;
		margin-right: 0;
	}
}

body#com_content.flexBlog {
	.blog-item {
		.itemWrap {
			.item-content {
				display: flex;
				flex-wrap: wrap;
				.textBlock {
					width: 100%;
					@media #{$small}{
						width: 60%;
					}
					@media #{$large}{
						width: 75%;
					}
				}
				figure.left {
					width: 100%;
					@media #{$small}{
						width: 40%;
					}
					@media #{$large}{
						width: 25%;
					}
					margin-top: auto;
					margin-bottom: auto;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					margin-right: 0;
					padding-right: 24px;
				}
			}
		}
	}
}

.formLoaderProducts{
	@include clear();
	.rsform{
		background: $bgbox;
		padding: 12px;
		height: 100%;
		margin-top: 24px;
		position: relative;
		box-shadow: $box-shadow;
		@media #{$large}{
			padding: 24px;
		}
	}
}
