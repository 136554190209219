.breadcrumb_wrap {
	width: 100%;
	float: left;
	.moduletable {
		ol.breadcrumb {
			list-style: none;
			margin-left: 0;
			display: block;
			float: left;
			background: $light;
			box-shadow: $box-shadow;
			padding-left: 12px;
			padding-right: 12px;
			padding-top: 6px;
			padding-bottom: 6px;
			margin-bottom: 24px;
			li {
				display: inline-block;
				span, a {
					padding: 0 5px;
					@media #{$large}{
						font-size: 14px;
					}
				}
				span{
					color: $base;
				}
				a{
					color: $link;
					&.pathway{
						padding: 0;
					}
				}
				&.breadcrumb-item {
					&:after{
						content: $icon-chevron-right;
						font-size: 10px;
						font-family: $icon;
						display: inline-block;
						color: $link;
						padding-left: 12px;
					}
					&:last-child{
						&:after{
							content: '';
							display: none;
						}
					}
				}
				.divider {
					padding: 0;

				}
				&:first-child {
					a, span{
						padding: 0;
					}
					.icon-location {
						&:before{
							content: $icon-home;
							padding-right: 0;
							font-size: 12px;
							@media #{$large}{
								font-size: 14px;
							}
						}
					}
				}
				&:nth-child(2){
					span.divider{
					}
				}
			}
		}
	}
}
.breadcrumb-item+.breadcrumb-item::before {
	display: none;
}
