.edit, form#modules-form {
	.btn-toolbar {
		float: left;
		width: 100%;
		clear: both;
		.btn-group, a.btn.modal-button {
			float: left;
			margin-right: 24px;
			display: block;
		}
		&#editor-xtd-buttons {
			padding-top: 12px;
			padding-bottom: 12px;
		}

	}
	/*Tab-Nav*/
	ul.nav.nav-tabs {
		li {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: #eee;
			&.active {
				background-color: #fff;
				border-left: 1px solid #eee;
				border-right: 1px solid #eee;
				border-top: 1px solid #eee;
			}
			&:before {
				content: '';
				display: none;
			}
		}
	}
	.tab-content {
		.tab-pane {
			display: none;
			&.active {
				display: block;
			}
			&#images, &#publishing {
				@media #{$large}{
					.control-group {
						float: left;
						width: 50%;
					}
				}
			}
			.control-group {
				margin-bottom: 12px;
			}
			&#images .control-group {
				&:nth-child(5), &:nth-child(9), &:nth-child(12), &:nth-child(15) {
					clear: both;
				}
			}
		}
	}
	/* Inputs */
	input[type=text], textarea {
		border: 1px solid #333;
		padding: 3px;
	}
	span.icon-calendar {
		&:before {
			content: '>';
		}
	}
	input[name="jform[publish_up]"], input[name="jform[publish_down]"] {
		max-width: 161px;
		font-size: 14px;
	}
	.chzn-container {
		min-width: 187px;
		.chzn-drop {
			ul.chzn-results {
				width: 100%;
				li {
					&:before {
						content: '';
						display: none;
					}
				}
			}
		}
		&.chzn-container-single {
			.chzn-single {
				height: 28px;
			}
		}
		&.chzn-container-multi {
			.chzn-choices {
				li.search-field {
					&:before {
						content: '';
						display: none;
					}
					input[type="text"] {
						height: auto;
					}
				}
				li.search-choice {
					&:before {
						content: '';
						display: none;
					}
					a.search-choice-close {
						&:before {
							content: 'x';
							display: block;
							color: grey;
							position: relative;
							font-size: 13px;
							text-align: center;
							line-height: 10px;
						}
					}
				}
			}
		}
	}
	input#jform_version_note {
		max-width: 187px;
	}
	&.item-page {
		overflow: visible;
	}
	table {
		tr {
			background-color: #eee !important;
			&:hover {
				background-color: #eee !important;
			}
		}
	}
	a.btn[data-original-title="Leeren"]:before {
		content: 'x';
		font-weight: bold;
		line-height: 1px;
	}

}

form#modules-form {
	padding: 24px;
	background: #fff;
	width: 100%;
	float: left;
	display: block;
	margin-bottom: 24px;
	clear: both;
	legend {
		font-weight: bold;
		margin-bottom: 12px;
	}
	span.label {
		background-color: #ddd;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 14px;
		color: grey;
	}
	hr {
		margin-top: 24px;
	}
	input[type="radio"] {
		margin-right: 5px;
		margin-left: 5px;
	}
	.control-group {
		float: left;
		width: 100%;
		@media #{$xlarge}{
			width: 50%;
		}
		.control-label {
			float: left;
			min-width: 38%;
			display: block;
			margin-bottom: 24px;
			clear: both;
			label {
				font-weight: bold;
				color: $dark;
			}
		}
	}
	.accordion-group {
		.accordion-heading {
			margin-bottom: 24px;
			display: block;
			width: 100%;
			float: left;
			clear: both;
		}
		.accordion-body {
			float: left;
			width: 100%;
			clear: both;
			input.input-small {
				max-width: 176px;
			}
			ul li.search-choice {
				padding-top: 5px;
				width: auto;
				padding-right: 20px;
			}
			ul.nav.nav-tabs.nav-stacked {
				width: 100%;
				li{
					width: 100%;
				}
				>li {
					width: 100%;
					padding-top: 24px;
					.control-label {
						margin-right: 24px;
					}
				}
			}
		}
	}
	label#jform_position-lbl, div#jformposition_chzn {
		display: none;
	}
}

.calendar table tbody tr td {
	padding: 2px 4px 2px 2px;
}

.icons{
	float: right;
	.btn-group{
		.dropdown-toggle{
			display: none;
		}
	}
	ul.dropdown-menu {
		list-style: none;
		margin-left: 12px;
		margin-bottom: 6px;
		li {
			display: inline;

			a {
				font-size: 12px;
				display: inline-block;
				background: $bg;
				padding: 0px 7px;
				border-radius: 3px;
			}
			&.edit-icon{
				a{
					&:before{
						content: $icon-cogs;
						padding-right: 4px;
						font-family: $icon;
					}
				}
			}
			&.print-icon{
				a{
					&:before{
						content: $icon-print;
						font-family: $icon;
						padding-right: 4px;
					}
				}
			}
			&.email-icon{
				a{
					&:before{
						content: $icon-envelope;
						font-family: $icon;
						padding-right: 4px;
					}
				}
			}
		}
	}
}
