.sketchwrap_slider {
	overflow: hidden;
	.sketchslider_pro-wrap {
		margin-top: 28px;
		.sketchslider_pro {
			margin-bottom: 24px;
			box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
			.box {
				color: $light;
				text-shadow: 0 0 3px #333;
				position: relative;
				bottom: auto;
				left: auto;
				background: $link;
				@include font-size(16);
				width: 100%;
				@media #{$large}{
					position: absolute;
					background: transparent;
					bottom: 48px;
					left: 12px;
					@include font-size(14);
					width: 100%;
					padding-left: 24px;
				}
				@media #{$xlarge}{
					@include font-size(18);
					max-width: 100%;
				}
				@media #{$hd}{
					padding-left: 32px;
				}
				span.slider_headline {
					@include font-size(28, 1.2);
					@media #{$xlarge}{
						@include font-size(36, 1.2);
					}
					font-weight: 700;
				}
				* {
					color: $light;
				}
				.btn {
					margin-top: 24px;
					@media #{$xlarge}{
						margin-top: 48px;
					}
					@include font-size(16);
				}
			}
			ul.slides {
				> li {
					.columns {
						min-height: 0;
					}
					@media #{$large}{
						&:before {
							content: "";
							position: absolute;
							right: 0;
							left: 0;
							bottom: 0;
							background: $link;
							opacity: 1;
							z-index: 2;
							height: 200%;
							width: 47%;
							border-radius: 0 50% 50% 0;
							top: -50%;
						}
					}
					.box {
						z-index: 3;
						.btn-transparent:hover {
							color: $base;
						}
					}
					img {
						z-index: 1;
					}
				}
			}
		}
	}
}