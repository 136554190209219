div.top_right {
	.moduletable {
		ul.nav {
			margin-top: 10px;
			margin-bottom: 10px;
			float: right;
			li {
				display: inline;
				list-style: none;
				margin-left: 12px;
				@media #{$xlarge}{
					margin-left: 24px;
				}
				&:first-child {
					margin-left: 0;
				}
				a {
					@include font-size(12);
				}
			}
		}
	}
}

nav.top_menu {
	.moduletable {
		float: left;
		overflow: visible;
		&.slidenav1 {
			padding: 0;
		}
		ul.nav.menu {
			margin-left: 0;
			margin-bottom: 0;
			> li {
				display: inline-block;
				list-style: none;
				position: relative;
				&.parent {
					> a, > span {
						&:after {
							content: '';
							font-family: monospace;
							position: absolute;
							transform: rotate(90deg) translate(2px, 50%);
							left: 50%;
							width: 0;
							height: 0;
							border-top: 6px solid transparent;
							border-bottom: 6px solid transparent;
							border-left: 6px solid $link;
							display: block;
						}
					}
				}
				> a, > span {
					color: $base;
					margin: 17px 7px 0;
					transition: .6s all ease 0s;
					text-decoration: none;
					display: block;
					@include font-size(13);
					font-weight: bold;
					padding-bottom: 32px;
					position: relative;
					cursor: pointer;
					background: $light;
					@media #{$xlarge}{
						margin: 17px 7px 0;
						@include font-size(16);
					}
					@media #{$hd}{
						margin: 17px 14px 0;
					}
					&:before {
						content: '';
						position: absolute;
						display: block;
						top: -12px;
						left: 0;
						width: 0;
						height: 3px;
						background: $link;
						color: $light;
						transition: .3s all ease 0s;
					}
					&.menu-icon-red {
						background: $link;
						color: $light;
						border-radius: 4px;
						padding: 8px 12px;
						&:before {
							display: none;
						}
						&:hover {
							background: $main;

						}
					}
				}
				&:hover {
					> a, > span {
						&:before {
							width: 100%;
						}
					}
				}
				ul.nav-child {
					position: absolute;
					top: 150%;
					left: 0;
					transition: .5s all ease 0s;
					margin-left: 0;
					width: auto;
					overflow: hidden;
					visibility: hidden;
					opacity: 0;
					background: $light;
					z-index: 99;
					box-shadow: 0 19px 20px -20px rgba(#444,.37), 0 12px 20px -12px rgba(#444,.36);

					li {
						display: block;
						float: none;
						margin: 0;
						list-style: none;
						position: relative;
						&:first-child a {
							border-left: 0;
						}
						a {
							@include font-size(14);
							padding: 8px 10px;
							margin: 0;
							background: $light;
							text-transform: none;
							letter-spacing: 0;
							font-weight: 500;
							color: $base;
							border-right: 0;
							transition: .6s all ease 0s;
							text-decoration: none;
							display: block;
							position: relative;
							cursor: pointer;
							white-space: nowrap;
							&:after {
								content: '';
							}
							&:first-child {
								border-left: 0;
							}
						}
						&:hover > a, &.active > a {
							color: $link_hover;
						}
					}
				}
				&:hover {
					ul.nav-child {
						opacity: 1;
						display: block;
						visibility: visible;
						top: 100%;
					}
				}
				&.active {
					> a, > span {
						color: $link;
					}
					ul {
						li {
							a {
							}
						}
					}
				}
			}
		}
	}
}

nav.bottom {
	.moduletable {
		ul.nav {
			margin-left: 0;
			li {
				display: inline;
				list-style: none;
				margin-right: 12px;
				@media #{$xlarge}{
					margin-right: 24px;
				}
				&:last-child {
					margin-right: 0;
				}
				a {
					@include font-size(14);
					color: $light;
				}
				a:hover {
					color: $link_hover;
				}
			}
		}
	}
}

.slidenav1, .slidenav2, .slidenav3, .slidenav4 {
	display: none;
	@media #{$large}{
		display: block;
	}
}

/* Icon 4 */
#main-nav {
	width: 40px;
	height: 35px;
	position: relative;
	margin: 12px auto;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	float: left;
	display: block;
	@media #{$large}{
		display: none;
	}
	span, &:before, &:after {
		display: block;
		position: absolute;
		height: 5px;
		width: 100%;
		background: $link;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;
	}
	&:before {
		top: 0px;
		content: '';
		transform-origin: left center;
	}
	&:after {
		content: '';
		top: 13px;
		transform-origin: left center;
	}
	span {
		top: 26px;
		transform-origin: left center;
	}
	&.open:before {
		transform: rotate(45deg);
		top: -3px;
		left: 8px;
	}
	&.open:after {
		width: 0;
		opacity: 0;
	}
	&.open span {
		transform: rotate(-45deg);
		top: 26px;
		left: 8px;
	}
	&.open {
		span, &:before, &:after {
			background: $main;
		}
	}
}
