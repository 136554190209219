body, html {
	font-size: var(--base-font-size);
	height: 100%;
	font-weight: 500;
}
// Sticky Footer
body{
	display: flex;
	background-attachment: fixed;
	flex-direction: column;
}
.allwrap{
	flex: 1 0 auto;
}
footer{
	flex-shrink: 0;
}
// end Sticky Footer
* {
	@include font-size(16);
	font-family: $base-font-family;
}

body {
	background-color: $bg;
	background-repeat: no-repeat;
	background-size: cover;
}

.allwrap {
	overflow: hidden;
}

header {
	width: 100%;
	box-shadow: 0px 3px 6px rgba(#000000, .13);
	position: relative;
	z-index: 1;
	.row.flex {
		> .columns {
			margin-bottom: 0;
			align-items: center;
			display: flex;
			&.right {
				justify-content: right;
				align-items: flex-end;
					min-height: 132px;
			}
			&.center{
				justify-content: center;
				align-items: center;
			}
		}
	}
	.logo {
		img {
			display: block;
			max-width: 100%;
			margin-top: 12px;
			width: $logoWidth;
		}
		float: left;
		margin-bottom: 6px;
	}
	.top_right {
		.moduletable.right {
			float: right;
			border-left: 3px solid $link;
			padding-top: 12px;
			padding-left: 24px;
			margin-top: 12px;
			max-height: 54px;
			@media #{$hd}{
				padding-left: 48px;
			}
			padding-bottom: 12px;
			a {
				@include font-size(20);
				&.icon-envelope {
					@include icon($icon-envelope, none);
				}
			}
		}
		.menuwrap {
			float: right;
			@media #{$hd}{
				margin-right: 24px;
			}
		}
	}
	&.isFixed{
		position: fixed;
		z-index: 11;
		background: $light;
		.logoRow{
			max-height: 400px;
			transition: .3s max-height ease 0s;
		}
		&.solid{
			min-height: 0;
			.logoRow{
				overflow: hidden;
				max-height: 0;
			}
		}
	}
}
.content_main_top, .content_main, .content_main_bottom{
	@include clear();
}
main {
	padding-top: 24px;
	padding-bottom: 48px;
	&.sliderTop{
		padding-top: 0;
	}
	min-height: 734px;
}
.item-page{
	@include clear();
	padding: 12px;
	@media #{$large}{
		padding: 24px;
	}
	background-color: $bgbox;
	box-shadow: 0 3px 6px #00000029;
}
body.home main {
	padding-top: 0;
	padding-bottom: 0;
}

footer {
	width: 100%;
	float: left;
	clear: both;
	background: $footer;
	padding-top: 24px;
	padding-bottom: 24px;
	a{
		color: $base;
		&:hover{
			color: $link_hover;
		}
	}

}

.match {
	transition: none !important;
}

body .cc_banner-wrapper:hover {
	a.cc_more_info {
		color: $main !important;
	}
	a.cc_btn.cc_btn_accept_all {
		background: $main !important;
		color: $light !important;
	}
}

.top_top {
	position: fixed;
	bottom: 0;
	width: 100%;
	box-shadow: 0 -1px 1px 0 rgba(50, 50, 50, .5);
	height: 5px;
	background: $footer;
	text-align: center;
	z-index: 2;
	&.stick {
		position: relative;
		bottom: 0 !important;
	}
	button.scroll_to_top {
		background: $footer;
		display: inline-block;
		height: 45px;
		position: relative;
		width: 45px;
		top: -44px;
		box-shadow: 0 -1px 1px 0 rgba(50, 50, 50, .5);
		z-index: 2;
		border-radius: 100% 100% 0 0;
		span.red_arrow {
			background: $light;
			color: $footer;
			display: block;
			width: 29px;
			height: 29px;
			border-radius: 100%;
			font-weight: lighter;
			line-height: 35px;
			font-size: 28px;
			margin: 0 auto;
			transition: .3s all ease 0s;
			@include icon($icon-angle-right, none);
			&:hover {
				background: $footer;
				color: $light;
			}
			&:before {
				transform: rotate(-90deg);
				display: block;
				transform-origin: center;
				padding-left: 6px;
			}
		}
	}
}

a.cc_more_info {
	font-size: inherit;
}

@media (prefers-color-scheme: dark) {
	:root {
		color-scheme: light !important;
	}
}

.visually-hidden {
	display: none;
}
.controls.chkheight {
	@include font-size(14);
}

fieldset{
	&.fieldset_download, &.fieldset_faq, &.fieldset_contact{
		border: 1px solid #d4d4d4;
		background-color: #f4f4f4;
		width: 100%;
		padding: 24px 10px 15px;
		text-align: center;
		legend {
			display: block;
			border: 1px solid #d4d4d4;
			background: #B80B08;
			color: #FFFFFF;
			font-size: 110%;
			font-weight: bold;
			text-align: left;
			padding: 4px 20px;
			margin-bottom: 0px;
			line-height: 100%;
			width: auto;
			min-width: 175px;
		}
	}
	&.fieldset_faq{
		text-align: left;
	}
}

.bfPage {
	width: 500px;
	max-width: 100%;
	margin: 0 auto;
}

.bfPage fieldset {
	padding: 0 !important;
	margin: 0 !important;
}

.bfPage section .bfClearfix.row {
	margin: 0;
}

#fbExtended{
	.NavLeftUL_navBackIcon.fas {
		right: 28px;
	}
	.fbContent_topics .level1 li a {
		color: #333;
		&:hover{
			color: $link;
		}
	}
	.fbTopic_description ul li a {
		color: #333;
		&:hover{
			color: $link;
		}
	}
}
body#com_faqbookpro {
	background: #fff;
}
